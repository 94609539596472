import $ from 'jquery'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './component.scss'
import 'Images/orange_glass.svg'

const TOP_MAIN_VISUAL_CLIENT_SEARCH_ROOT_SELECTOR = '.js-pcTopMainVisualSearchClient-root'
const OPEN_PREFECTURE_TRIGGER_SELECTOR = '.js-openMainVisualPrefecture-trigger'
const OPEN_PREFECTURE_TARGET_SELECTOR = '.js-openMainVisualPrefecture-target'
const REGION_ITEM_ACTIVE_CLASS_NAME = 'top-main-visual-client-search__region-item--active'
const PREFECTURE_GROUP_ACTIVE_CLASS_NAME = 'top-main-visual-client-search__prefecture-group--active'
const TOP_MAIN_VISUAL_CLIENT_SEARCH_ACTIVE_CLASS_NAME = 'top-main-visual-client-search--active'
const PREFECTURE_LIST_SELECTOR = '.top-main-visual-client-search__prefecture-list'
const BUBBLE_SELECTOR = '.top-main-visual-client-search__bubble'
const BUBBLE_WIDTH = 200
const TABLET_WIDTH = 999

function handleMouseOver(event) {
  const trigger = $(event.currentTarget)
  const regionId = trigger.data('region-id')
  const target = trigger.closest(TOP_MAIN_VISUAL_CLIENT_SEARCH_ROOT_SELECTOR).find(`${OPEN_PREFECTURE_TARGET_SELECTOR}[data-region-id="${regionId}"]`)

  $(OPEN_PREFECTURE_TRIGGER_SELECTOR).removeClass(REGION_ITEM_ACTIVE_CLASS_NAME)
  $(OPEN_PREFECTURE_TARGET_SELECTOR).removeClass(PREFECTURE_GROUP_ACTIVE_CLASS_NAME)
  $(TOP_MAIN_VISUAL_CLIENT_SEARCH_ROOT_SELECTOR).removeClass(TOP_MAIN_VISUAL_CLIENT_SEARCH_ACTIVE_CLASS_NAME)

  trigger.addClass(REGION_ITEM_ACTIVE_CLASS_NAME)
  target.addClass(PREFECTURE_GROUP_ACTIVE_CLASS_NAME)
  $(TOP_MAIN_VISUAL_CLIENT_SEARCH_ROOT_SELECTOR).addClass(TOP_MAIN_VISUAL_CLIENT_SEARCH_ACTIVE_CLASS_NAME)

  const triggerOffset = trigger.offset()
  const triggerWidth = trigger.outerWidth()
  const prefectureList = target.find(PREFECTURE_LIST_SELECTOR)
  const prefectureListWidth = prefectureList.outerWidth()
  const bubble = target.find(BUBBLE_SELECTOR)

  let leftPosition = triggerOffset.left + (triggerWidth / 2) - (prefectureListWidth / 2)
  if (leftPosition + prefectureListWidth > $(window).width()) {
    leftPosition = $(window).width() - prefectureListWidth - 10
  }

  prefectureList.css({
    left: leftPosition
  })
  bubble.css({
    left: triggerOffset.left + (triggerWidth / 2) - (BUBBLE_WIDTH / 2)
  })
}

function handleMouseOut(event) {
  const trigger = $(event.currentTarget)
  const regionId = trigger.data('region-id')
  const target = trigger.closest(TOP_MAIN_VISUAL_CLIENT_SEARCH_ROOT_SELECTOR).find(`${OPEN_PREFECTURE_TARGET_SELECTOR}[data-region-id="${regionId}"]`)

  if (!target.is(':hover') && !trigger.is(':hover')) {
    target.removeClass(PREFECTURE_GROUP_ACTIVE_CLASS_NAME)
    $(OPEN_PREFECTURE_TRIGGER_SELECTOR).removeClass(REGION_ITEM_ACTIVE_CLASS_NAME)
    $(TOP_MAIN_VISUAL_CLIENT_SEARCH_ROOT_SELECTOR).removeClass(TOP_MAIN_VISUAL_CLIENT_SEARCH_ACTIVE_CLASS_NAME)
  }
}

function handleClick(event) {
  const trigger = $(event.currentTarget)
  const regionId = trigger.data('region-id')
  const target = trigger.closest(TOP_MAIN_VISUAL_CLIENT_SEARCH_ROOT_SELECTOR).find(`${OPEN_PREFECTURE_TARGET_SELECTOR}[data-region-id="${regionId}"]`)

  $(OPEN_PREFECTURE_TRIGGER_SELECTOR).removeClass(REGION_ITEM_ACTIVE_CLASS_NAME)
  $(OPEN_PREFECTURE_TARGET_SELECTOR).removeClass(PREFECTURE_GROUP_ACTIVE_CLASS_NAME)
  $(TOP_MAIN_VISUAL_CLIENT_SEARCH_ROOT_SELECTOR).removeClass(TOP_MAIN_VISUAL_CLIENT_SEARCH_ACTIVE_CLASS_NAME)

  trigger.addClass(REGION_ITEM_ACTIVE_CLASS_NAME)
  target.addClass(PREFECTURE_GROUP_ACTIVE_CLASS_NAME)
  $(TOP_MAIN_VISUAL_CLIENT_SEARCH_ROOT_SELECTOR).addClass(TOP_MAIN_VISUAL_CLIENT_SEARCH_ACTIVE_CLASS_NAME)

  const triggerOffset = trigger.offset()
  const triggerWidth = trigger.outerWidth()
  const prefectureList = target.find(PREFECTURE_LIST_SELECTOR)
  const prefectureListWidth = prefectureList.outerWidth()
  const bubble = target.find(BUBBLE_SELECTOR)

  let leftPosition = triggerOffset.left + (triggerWidth / 2) - (prefectureListWidth / 2)
  if (leftPosition + prefectureListWidth > $(window).width()) {
    leftPosition = $(window).width() - prefectureListWidth - 10
  }

  prefectureList.css({
    left: leftPosition
  })
  bubble.css({
    left: triggerOffset.left + (triggerWidth / 2) - (BUBBLE_WIDTH / 2)
  })
}

function handleDocumentClick(event) {
  if (!$(event.target).closest(OPEN_PREFECTURE_TRIGGER_SELECTOR).length && !$(event.target).closest(OPEN_PREFECTURE_TARGET_SELECTOR).length) {
    $(OPEN_PREFECTURE_TRIGGER_SELECTOR).removeClass(REGION_ITEM_ACTIVE_CLASS_NAME)
    $(OPEN_PREFECTURE_TARGET_SELECTOR).removeClass(PREFECTURE_GROUP_ACTIVE_CLASS_NAME)
    $(TOP_MAIN_VISUAL_CLIENT_SEARCH_ROOT_SELECTOR).removeClass(TOP_MAIN_VISUAL_CLIENT_SEARCH_ACTIVE_CLASS_NAME)
  }
}

function setupEventHandlers() {
  if ($(window).width() > TABLET_WIDTH) {
    $(OPEN_PREFECTURE_TRIGGER_SELECTOR).off('click', handleClick)
    $(OPEN_PREFECTURE_TRIGGER_SELECTOR).on('mouseover', handleMouseOver)
    $(OPEN_PREFECTURE_TRIGGER_SELECTOR).add(OPEN_PREFECTURE_TARGET_SELECTOR).on('mouseout', handleMouseOut)
    $(document).off('click', handleDocumentClick)
  } else {
    $(OPEN_PREFECTURE_TRIGGER_SELECTOR).on('click', handleClick)
    $(document).on('click', handleDocumentClick)
  }
}

$(() => {
  setupEventHandlers()
  $(window).on('resize', setupEventHandlers)
})
