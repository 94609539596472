import './component.scss'
import $ from 'jquery'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'Images/green_glass.svg'

const CLIENT_SEARCH_ROOT_SELECTOR = '.js-clientSearch-root'
const PREFECTURE_SELECTOR = '.js-prefecture-select'
const CITY_SELECTOR = '.js-city-select'
const PART_TYPE_SELECTOR = '.js-partType-radio'

const fetchCitiesData = async (prefectureName, partTypeUrl) => {
  const response = await fetch(`/api/prefecture/${prefectureName}/enable_cities_for_part_type/?part_type_url=${partTypeUrl}`)
  const cities = await response.json()

  return cities
}

const resetCity = ($clientSearchRoot) => {
  const $citySelect = $clientSearchRoot.find(CITY_SELECTOR)
  $citySelect.val('')
  $citySelect.html('')
  $citySelect.append('<option value="">-市区町村</option>')
}

const updateCitySelect = async ($clientSearchRoot, selectedCityId = 0) => {
  const $citySelect = $clientSearchRoot.find(CITY_SELECTOR)
  const $prefectureSelect = $clientSearchRoot.find(PREFECTURE_SELECTOR)
  const $partTypeRadios = $clientSearchRoot.find(PART_TYPE_SELECTOR)

  const prefectureName = $prefectureSelect.val()
  const partTypeUrl = $partTypeRadios.filter(':checked').val()

  const cities = await fetchCitiesData(prefectureName, partTypeUrl)

  resetCity($clientSearchRoot)
  cities.forEach(city => {
    const option = document.createElement('option')
    option.value = city.id
    option.text = city.name

    option.disabled = !city.enabled
    option.selected = city.id == selectedCityId

    $citySelect.append(option)
  })
}

const clientSearch = ($clientSearchRoot) => {
  const $prefectureSelect = $clientSearchRoot.find(PREFECTURE_SELECTOR)
  const $citySelect = $clientSearchRoot.find(CITY_SELECTOR)
  const $partTypeRadios = $clientSearchRoot.find(PART_TYPE_SELECTOR)

  const prefectureName = $prefectureSelect.val()
  const cityId = $citySelect.val()
  const partTypeUrl = $partTypeRadios.filter(':checked').val()

  let url = `/area/${prefectureName}`
  if (cityId) {
    url += `/${cityId}`
  } else {
    url += '/part'
  }
  url += `/${partTypeUrl}`

  window.location.href = url
}

const handleInputChange = (event) => {
  const $currentTarget = $(event.currentTarget)
  const $clientSearchRoot = $currentTarget.closest(CLIENT_SEARCH_ROOT_SELECTOR)

  resetCity($clientSearchRoot)
  updateCitySelect($clientSearchRoot)
}

$(() => {
  const $rootElements = $(CLIENT_SEARCH_ROOT_SELECTOR)

  $rootElements.each((_index, root) => {
    const $root = $(root)
    const $citySelect = $root.find(CITY_SELECTOR)
    const currentCityId = $citySelect.val()
    updateCitySelect($root, currentCityId)
  })

  const $clientSearchButton = $('.js-clientSearch-button')
  $clientSearchButton.on('click', (event) => {
    const $currentTarget = $(event.currentTarget)
    const $clientSearchRoot = $currentTarget.closest(CLIENT_SEARCH_ROOT_SELECTOR)

    clientSearch($clientSearchRoot)
  })

  const $prefectureSelect = $(PREFECTURE_SELECTOR)
  $prefectureSelect.on('change', handleInputChange)

  const $partTypeRadios = $(PART_TYPE_SELECTOR)
  $partTypeRadios.on('change', handleInputChange)
})
