import $ from 'jquery'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import './component.scss'
import 'Components/nurikae/client_search/component'
import './images/client_search_modal_close.svg'
import 'Images/orange_glass.svg'

const CLIENT_SEARCH_MODAL_ROOT_SELECTOR = '.js-clientSearchModal-root'
const CLIENT_SEARCH_MODAL_OVERLAY_SELECTOR = '.js-clientSearchModal-overlay'
const CLIENT_SEARCH_MODAL_CONTENT_SELECTOR = '.js-clientSearchModal-content'
const CLIENT_SEARCH_MODAL_OPEN_SELECTOR = '.js-clientSearchModal-open'
const CLIENT_SEARCH_MODAL_CLOSE_SELECTOR = '.js-clientSearchModal-close'
const OVERLAY_ACTIVE_CLASS = 'client-search-modal-for-top__overlay--active'
const MODAL_ACTIVE_CLASS = 'client-search-modal-for-top__content--active'

const openModal = () => {
  $('body').css('overflow', 'hidden')
  const $modalRoot = $(CLIENT_SEARCH_MODAL_ROOT_SELECTOR)
  $modalRoot.find(CLIENT_SEARCH_MODAL_CONTENT_SELECTOR).addClass(MODAL_ACTIVE_CLASS)
  $modalRoot.find(CLIENT_SEARCH_MODAL_OVERLAY_SELECTOR).addClass(OVERLAY_ACTIVE_CLASS)
}

const closeModal = () => {
  $('body').css('overflow', '')
  const $modalRoot = $(CLIENT_SEARCH_MODAL_ROOT_SELECTOR)
  $modalRoot.find(CLIENT_SEARCH_MODAL_CONTENT_SELECTOR).removeClass(MODAL_ACTIVE_CLASS)
  $modalRoot.find(CLIENT_SEARCH_MODAL_OVERLAY_SELECTOR).removeClass(OVERLAY_ACTIVE_CLASS)
}

$(() => {
  const $modalRoot = $(CLIENT_SEARCH_MODAL_ROOT_SELECTOR)
  const $modalOverlay = $modalRoot.find(CLIENT_SEARCH_MODAL_OVERLAY_SELECTOR)
  const $openModalButton = $(CLIENT_SEARCH_MODAL_OPEN_SELECTOR)
  const $closeModalButton = $(CLIENT_SEARCH_MODAL_CLOSE_SELECTOR)

  $openModalButton.on('click', () => {
    openModal()
  })

  $closeModalButton.on('click', () => {
    closeModal()
  })

  $modalOverlay.on('click', () => {
    closeModal()
  })
})
